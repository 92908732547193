import React from 'react'
import {graphql} from 'gatsby'
import SEO from '../../components/seo';
import Layout from '../../containers/layout/layout'
import Banner from '../../containers/blog/banner'
import BlogHeading from '../../containers/blog/blog-heading'
import Blog from '../../components/blog/layout-one'
import Pagination from '../../components/pagination'
import {SectionWrap, BlogInner} from './blog-list.stc'

const BlogList = (props) => {
    const blogs = props.data.allDatoCmsBlog.edges;
    const {currentPage, numberOfPages} = props.pageContext;

    return(
        <Layout>
            <SEO title={`Mimarlık Blog - Sayfa ${currentPage}`}/>
            <Banner/>
            <SectionWrap>
                <BlogHeading/>
                <BlogInner>
                    <div className="col-4 offset-1">
                        <div className="row">
                            {blogs.map((blog, i) => (
                                <div className="col-2 blog-wrap" key={`blog-${i}`}>
                                    <Blog
                                        title={blog.node.title}
                                        date={blog.node.date}
                                        author={blog.node.author}
                                        id={blog.node.id}
                                        path={`${blog.node.slug}`}
                                        excerpt={blog.node.desc}
                                        image={blog.node.image.fluid}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </BlogInner>
            </SectionWrap>
            <Pagination
                rootPage="/blog"
                currentPage={currentPage}
                numberOfPages={numberOfPages}
            />
        </Layout>
    )
}

export const query = graphql `
    query BlogListQuery($skip: Int!, $limit: Int!){
        allDatoCmsBlog(
            sort: {fields: [date], order: DESC}, 
            limit: $limit, 
            skip: $skip) {
                edges {
                    node {
                        id
                        title
                        author
                        slug
                        desc
                        date(formatString: "Do MMMM, YYYY" locale:"tr")
                        image {
                          fluid(maxWidth: 600, maxHeight: 630) {
                              ...GatsbyDatoCmsFluid_noBase64
                            }
                        }
                    }
                }
        }
    }
`;


export default BlogList;